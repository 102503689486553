import React, { useState } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { Upload, FileUp, AlertTriangle, File } from 'lucide-react';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

function Encode({ user, db }) {
  const [file, setFile] = useState(null);
  const [versions, setVersions] = useState(1);
  const [encodedFiles, setEncodedFiles] = useState([]);
  const [keys, setKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size > MAX_FILE_SIZE) {
      setError("File size exceeds the limit of 5 MB");
      setFile(null);
    } else {
      setFile(selectedFile);
      setError(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!file) {
      setError("Please select a file");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('versions', versions);

    try {
      const response = await fetch(`${backendUrl}/encode`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Encoding failed');
      }

      const data = await response.json();
      setEncodedFiles(data.files);
      setKeys(data.keys);

      // Store the encoded files and keys in Firestore
      if (user) {
        await addDoc(collection(db, "encoded_documents"), {
          userId: user.uid,
          fileName: file.name,
          encodedFiles: data.files,
          keys: data.keys,
          createdAt: serverTimestamp()
        });
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg shadow-md">
      <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-8">Encode Document</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Upload Document (.docx, max 5MB)</label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            {!file ? (
              <div className="space-y-1 text-center">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                    <span>Upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={handleFileChange}
                      accept=".docx"
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">DOCX up to 5MB</p>
              </div>
            ) : (
              <div className="flex items-center space-x-2">
                <File className="h-8 w-8 text-blue-500" />
                <span className="text-sm text-gray-900">{file.name}</span>
              </div>
            )}
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Number of Versions</label>
          <input
            type="number"
            value={versions}
            onChange={(e) => {
              const value = e.target.value > 100 ? 100 : e.target.value;
              setVersions(value);
            }}
            className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
            min="1"
            max="100"
          />
        </div>
        <button
          type="submit"
          disabled={isLoading || !user || !file}
          className={`w-full py-3 px-4 flex items-center justify-center text-white rounded-md shadow-sm transition duration-300 ${isLoading || !user || !file ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            }`}
        >
          <FileUp className="w-5 h-5 mr-2" />
          {isLoading ? 'Encoding...' : 'Generate Versions'}
        </button>
      </form>
      {error && (
        <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-md">
          <div className="flex items-center">
            <AlertTriangle className="w-5 h-5 mr-2" />
            {error}
          </div>
        </div>
      )}
      {!user && (
        <div className="mt-8 p-4 bg-yellow-100 border border-yellow-400 text-yellow-700 rounded-md">
          Please log in to access the document encoding feature.
        </div>
      )}
      {encodedFiles.length > 0 && (
        <div className="mt-12">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6">Encoded Files:</h3>
          <ul className="space-y-4">
            {encodedFiles.map((file, index) => (
              <li key={index} className="p-4 bg-white border border-gray-200 rounded-md shadow-sm hover:shadow-md transition duration-300">
                <div className="flex items-center justify-between">
                  <span className="text-lg font-medium text-gray-800">Variation {index}</span>
                  <a
                    href={`${backendUrl}/download/${file}`}
                    download
                    className="text-blue-500 hover:text-blue-700 font-medium flex items-center"
                  >
                    <FileUp className="w-5 h-5 mr-2" />
                    Download
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Encode;