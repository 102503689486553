import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { collection, addDoc, query, where, orderBy, getDocs, serverTimestamp } from 'firebase/firestore';
import Home from './components/Home';
import Encode from './components/Encode';
import Decode from './components/Decode';
import UserDocuments from './components/UserDocuments';
import logo from './logo.png'
import { UserCircle, LogIn, LogOut } from 'lucide-react';

// Initialize Firebase (replace with your config)
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const signIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
    } catch (error) {
      console.error("Error signing in", error);
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <Router>
      <div className="min-h-screen bg-white">
        <Navbar user={user} signIn={signIn} signOut={signOut} />
        <div className="max-w-5xl mx-auto px-4 py-12">
          <Routes>
            <Route path="/" element={<Home user={user} signIn={signIn} />} />
            <Route path="/encode" element={<Encode user={user} db={db} />} />
            <Route path="/decode" element={<Decode user={user} db={db} />} />
            <Route path="/my-documents" element={<UserDocuments user={user} db={db} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

function Navbar({ user, signIn, signOut }) {
  const location = useLocation();

  return (
    <nav className="bg-white shadow-sm border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <Link to="/" className="flex items-center space-x-3">
            <img src={logo} alt="DocIdentity Logo" className="h-8" />
            {/* <span className="text-gray-800 font-bold text-xl">DocIdentity</span> */}
          </Link>
          <div className="flex items-center space-x-4">
            <NavLink to="/encode" active={location.pathname === '/encode'}>Encode</NavLink>
            <NavLink to="/decode" active={location.pathname === '/decode'}>Decode</NavLink>
            {!user ? (
              <button 
                onClick={signIn} 
                className="py-2 px-4 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 flex items-center"
              >
                <LogIn className="w-5 h-5 mr-2" />
                Log In
              </button>
            ) : (
              <div className="flex items-center space-x-3">
                <Link to="/my-documents" className="flex items-center space-x-3">
                  {user.photoURL ? (
                    <img src={user.photoURL} alt="User avatar" className="w-8 h-8 rounded-full border-2 border-gray-200" />
                  ) : (
                    <UserCircle className="w-8 h-8 text-gray-600" />
                  )}
                  <span className="font-medium text-gray-700">{user.displayName}</span>
                </Link>
                <button 
                  onClick={signOut} 
                  className="py-2 px-4 bg-gray-100 text-gray-700 rounded-md shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-300 flex items-center"
                >
                  <LogOut className="w-5 h-5 mr-2" />
                  Log Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

function NavLink({ to, children, active }) {
  return (
    <Link
      to={to}
      className={`py-2 px-3 rounded-md transition duration-300 ${
        active
          ? 'bg-gray-100 text-blue-600 font-medium'
          : 'text-gray-600 hover:bg-gray-100 hover:text-blue-600'
      }`}
    >
      {children}
    </Link>
  );
}


export default App;