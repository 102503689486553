import React from "react";
import { Link } from "react-router-dom";
import { Stamp, FileSearch } from 'lucide-react';

function Home({ user, signIn }) {
  return (
    <div className="text-center p-8 bg-gradient-to-br flex flex-col justify-center items-center">
      <h1 className="text-6xl font-extrabold text-blue-600 mb-6">
        DocIdentity
      </h1>
      <p className="text-xl text-gray-700 mb-12 max-w-xl">
        Upload documents, generate steganographic versions, and decode hidden messages.
      </p>
      {user ? (
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-6">
          <Link
            to="/encode"
            className="group relative px-8 py-3 bg-blue-600 text-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300 ease-out"
          >
            <span className="relative z-10 flex items-center justify-center">
              <Stamp className="w-5 h-5 mr-2" />
              <span className="relative z-10">Encode Document</span>
            </span>
            <span className="absolute top-0 left-0 w-0 h-full bg-blue-700 transition-all duration-300 ease-out group-hover:w-full"></span>
          </Link>
          <Link
            to="/decode"
            className="group relative px-8 py-3 bg-blue-600 text-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300 ease-out"
          >
            <span className="relative z-10 flex items-center justify-center">
              <FileSearch className="w-5 h-5 mr-2" />
              <span className="relative z-10">Decode Document</span>
            </span>
            <span className="absolute top-0 left-0 w-0 h-full bg-blue-700 transition-all duration-300 ease-out group-hover:w-full"></span>
          </Link>
        </div>
      ) : (
        <div className="text-center">
          <p className="text-xl text-gray-700 mb-6 max-w-xl">
            Access our advanced document encoding and decoding features by logging in.
          </p>
          <button
            onClick={signIn}
            className="group relative px-8 py-3 bg-blue-600 text-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300 ease-out"
          >
            <span className="relative z-10">Log In</span>
            <span className="absolute top-0 left-0 w-0 h-full bg-blue-700 transition-all duration-300 ease-out group-hover:w-full"></span>
          </button>
        </div>
      )}
    </div>
  );
}

export default Home;