import { useEffect, useState } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function UserDocuments({ user, db }) {
    const [documents, setDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (user) {
            const fetchDocuments = async () => {
                try {
                    const q = query(collection(db, "encoded_documents"), where("userId", "==", user.uid), orderBy("createdAt", "desc"));
                    const querySnapshot = await getDocs(q);
                    const docs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setDocuments(docs);
                } catch (err) {
                    setError("Failed to fetch documents");
                    console.error(err);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchDocuments();
        }
    }, [user]);

    if (!user) {
        return <p>Please log in to view your documents.</p>;
    }

    if (isLoading) {
        return <p>Loading your documents...</p>;
    }

    if (error) {
        return <p className="text-red-500">{error}</p>;
    }

    return (
        <div>
            <h2 className="text-3xl font-light text-gray-800 mb-8">My Encoded Documents</h2>
            {documents.length === 0 ? (
                <p>You haven't encoded any documents yet.</p>
            ) : (
                <ul className="space-y-6">
                    {documents.map((doc) => (
                        <li key={doc.id} className="border-t border-gray-200 pt-4">
                            <h3 className="text-xl font-medium text-gray-800">{doc.fileName}</h3>
                            <p className="text-sm text-gray-600 mt-1">Encoded on: {doc.createdAt.toDate().toLocaleString()}</p>
                            {/* <ul className="mt-2 space-y-2">
                                {doc.encodedFiles.map((file, index) => (
                                    <li key={index} className="flex items-center justify-between">
                                        <a
                                            href={backendUrl + `/download/${file}`}
                                            download
                                            className="text-blue-600 hover:text-blue-800 font-medium"
                                        >
                                            {file}
                                        </a>
                                    </li>
                                ))}
                            </ul> */}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default UserDocuments;