import React, { useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { FileSearch, Upload, Key, File } from 'lucide-react';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function Decode({ user, db }) {
  const [decodeMethod, setDecodeMethod] = useState('snippet');
  const [snippet, setSnippet] = useState('');
  const [file, setFile] = useState(null);
  const [decodedKey, setDecodedKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [matchedDocuments, setMatchedDocuments] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setDecodedKey(null);
    setMatchedDocuments([]);

    const formData = new FormData();
    if (decodeMethod === 'snippet') {
      formData.append('snippet', snippet);
    } else {
      if (!file) {
        setError("Please select a file");
        setIsLoading(false);
        return;
      }
      formData.append('file', file);
    }

    try {
      const response = await fetch(`${backendUrl}/decode`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Decoding failed. Text snippet might be too short or file is invalid.');
      }

      const data = await response.json();
      setDecodedKey(data.generated_key);

      if (user) {
        const encodedDocsQuery = query(
          collection(db, "encoded_documents"),
          where("userId", "==", user.uid)
        );
        const querySnapshot = await getDocs(encodedDocsQuery);

        const matches = [];
        querySnapshot.forEach((doc) => {
          const encodedDoc = doc.data();
          encodedDoc.keys.forEach((key, index) => {
            if (data.generated_key.includes(key)) {
              matches.push({
                fileName: encodedDoc.fileName,
                documentId: index,
              });
            }
          });
        });

        setMatchedDocuments(matches);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg shadow-md">
      <h2 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-8">Decode Document</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Decode Method</label>
          <select
            value={decodeMethod}
            onChange={(e) => setDecodeMethod(e.target.value)}
            className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
          >
            <option value="snippet">Text Snippet</option>
            <option value="full">Full Document</option>
          </select>
        </div>
        {decodeMethod === 'snippet' ? (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Text Snippet</label>
            <textarea
              value={snippet}
              onChange={(e) => setSnippet(e.target.value)}
              className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
              rows="4"
            ></textarea>
          </div>
        ) : (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Upload Document (.docx)</label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              {!file ? (
                <div className="space-y-1 text-center">
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                      <span>Upload a file</span>
                      <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFileChange} accept=".docx" />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">DOCX up to 10MB</p>
                </div>
              ) : (
                <div className="flex items-center space-x-2">
                  <File className="h-8 w-8 text-blue-500" />
                  <span className="text-sm text-gray-900">{file.name}</span>
                </div>
              )}
            </div>
          </div>
        )}
        <button
          type="submit"
          disabled={isLoading || !user}
          className={`w-full py-3 px-4 flex items-center justify-center text-white rounded-md shadow-sm transition duration-300 ${isLoading || !user ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            }`}
        >
          <FileSearch className="w-5 h-5 mr-2" />
          {isLoading ? 'Decoding...' : 'Decode'}
        </button>
      </form>
      {error && (
        <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-md">
          {error}
        </div>
      )}
      {!user && (
        <div className="mt-8 p-4 bg-yellow-100 border border-yellow-400 text-yellow-700 rounded-md">
          Please log in to access the document decoding feature.
        </div>
      )}
      {matchedDocuments.length > 0 ? (
        <div className="mt-12">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6">Matched Documents</h3>
          <ul className="space-y-4">
            {matchedDocuments.map((doc, index) => (
              <li key={index} className="p-4 bg-white border border-gray-200 rounded-md shadow-sm hover:shadow-md transition duration-300">
                <div className="flex items-center">
                  <Key className="w-6 h-6 text-blue-500 mr-3" />
                  <div>
                    <div className="text-lg font-medium text-gray-800">{doc.fileName}</div>
                    <div className="text-sm text-gray-600">Variation ID: <span className="font-mono font-bold">{doc.documentId}</span></div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        decodedKey && <p className="mt-12 text-gray-700 text-center">No matched documents found.</p>
      )}
    </div>
  );
}

export default Decode;